.qr-loyalty-wrapper {
  padding: 20px 5px;
}

.qr-title {
  text-align: center;
  margin: 10px 0px;
}

.qr-code {
  border-radius: 8px;
  padding: 20px 30px 20px 30px;
}

.reset-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
