.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #444440;
  box-shadow: 0 3px 6px #000;
}

.contact {
  position: absolute;
  right: 0;
}

.contact h4 {
  margin: 0;
}

.blank {
  width: 90px;
}

.wrap-logo {
  width: 130px;
  aspect-ratio: 1;
}
.img-logo {
  width: 100%;
}

.wrap-header {
  width: 100%;
}

.merchant-login-btn {
  position: absolute;
  right: 0;
  color: #000000;
  background-color: #FF9327;
  padding: 6px 10px;
  border: none;
  outline: none;
  border-radius: 20px;
  min-width: 70px;
  margin-right: 20px;
}

.back-btn {
  position: absolute;
  left: 10px;
  top: 40px;
  width: 30px !important;
  height: 30px !important;
}

.back-icon {
  color: #ffffff;
}