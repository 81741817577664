.otp-input {
  margin: 5px;
  width: 30px;
  height: 50px;
  border: 1px solid #ff9327;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 5px;
}

.otp-container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Button {
  padding: 10px 80px !important;
  color: #000 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.Button-disable {
  background-color: #bfbebe !important;
}

.Button-active {
  background-color: #5d8d66 !important;
  color: #ffffff !important;
}
