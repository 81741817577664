.verify-code-wrapper {
  padding: 20px 20px 0px 20px;
}

.confirm-btn {
  margin-top: 20px;
}

.phone-input {
  height: 10px;
}